(function () {
    this.TradeChart = (function () {
        function TradeChart() {
            this.setListener();
        }

        TradeChart.prototype.setListener = function () {

        	$(document).ready(function(){
                var chart = null;
                laod_line_chart();
                laod_pairs();
                setInterval(laod_line_chart, 300000); // 300秒ごと
                setInterval(laod_pairs, 300000); // 300秒ごと

                function laod_line_chart() {
                    var form = $('#load_chart');
                    if (!form.length) return;
                    var success = function(res) {
                      var data = res.chart.reverse();
                      var ctx = document.getElementById('line_chart').getContext('2d');
                      ctx.canvas.style.height = '230px';
                      if (chart) chart.destroy();
                      chart = new Chart(ctx, {
                        type: 'line',
                        data: {
                            labels: $.map(data, function(v, i) {
                              if (form.find('[name=period]').val() > 3600) {
                                return local_datetime(v.date).substr(5, 5);
                              } else {
                                return local_datetime(v.date).substr(-5)
                              }
                            }),
                            datasets: [{
                                label: form.find('[name=currency]').val().toUpperCase() +'/'+ form.find('[name=base_currency]').val().toUpperCase(),
                                data: $.map(data, function(v, i) { return v.rate }),
                                pointRadius: 0,
                                lineTension: 0,
                                borderColor: "rgba(22, 129, 255, 0.5)",
                                borderWidth: 1.5,
                                backgroundColor: "rgba(93, 171, 255, 0.5)",
                            }]
                        },
                        options: {
                            responsive: true,
                            maintainAspectRatio: false,
                        }
                      });

                      set_selected_pair();
                      sort_pairs();
                    };
                    var fail = function() {}
                    ajax_load(form, success, fail);
                }

                function laod_pairs() {
                    var form = $('#load_chart_pair');
                    if (!form.length) return;
                    var success = function(res) {
                      form.find('ul a').remove();
                      Object.keys(res.pairs).forEach(function (key) {
                        var row = res.pairs[key]
                        var a_li = '<a href="javascript:void(0)" data-action="load_chart" data-exchange_id="'+row.exchange_id+'" data-currency="'+row.currency+'" data-base_currency="'+row.base_currency+'"><li>' +
                            '    <img class="currency_img" src="/images/currency/'+row.currency+'.png" onerror="this.src=\'/images/currency/default.png\';">' +
                            '    <div class="currency">'+row.currency.toUpperCase()+'</div>' +
                            '    <div class="rate">'+round(row.rate, (row.base_currency == "jpy" ? 4 : 8), true)+' <span class="currency">'+row.base_currency.toUpperCase()+'</div>' +
                            '</li></a>'
                        ;
                        ["btc", "eth"].includes(row.currency) ? form.find('ul.top').append(a_li) : form.find('ul.bottom').append(a_li);
                      });
                      set_selected_pair();
                      sort_pairs();
                      resize_pair_list();
                    };
                    var fail = function() {}
                    ajax_load(form, success, fail);
                }

                function set_selected_pair() {
                  $('#load_chart_pair').find('ul li').removeClass("selected");
                  $.each($('#load_chart_pair').find('ul a'), function(i, v) {
                    if ($(this).data("exchange_id") == $('[name=exchange_id]').val() &&
                        $(this).data("currency") == $('[name=currency]').val() &&
                        $(this).data("base_currency") == $('[name=base_currency]').val()) {
                        $(this).find('li').addClass("selected");
                    }
                  });
                }

                function sort_pairs() {
                  $.each($('#load_chart_pair').find('ul a'), function(i, v) {
                    if ($(this).data("base_currency") == $('[name=base_currency]').val() &&
                        ($(this).data("currency").indexOf($('[name=search_currency]').val().toLowerCase()) >= 0 || $('[name=search_currency]').val() == "")) {
                        $(this).show();
                    } else {
                      $(this).hide();
                    }
                  });
                }

                $(document).on('input', '[name=search_currency]', function () {
                  sort_pairs();
                });

                $(document).on('change', '[name=base_currency]', function () {
                  $('[name=search_currency]').val("");
                  $.each($('#load_chart_pair').find('ul a'), function(i, v) {
                    if ($(this).data("base_currency") == $('[name=base_currency]').val() &&
                        $(this).data("currency") == $('[name=currency]').val()) {
                        $(this).click();
                        return;
                    }
                  });
                  $.each($('#load_chart_pair').find('ul a'), function(i, v) {
                    if (($(this).data("currency") == "btc" && $(this).data("base_currency") == $('[name=base_currency]').val()) ||
                        ($(this).data("currency") == "eth" && $(this).data("base_currency") == $('[name=base_currency]').val())) {
                        $(this).click();
                        return;
                    }
                  });
                });

                $(document).on('change', '[name=period]', function () {
                  $('[name=search_currency]').val("");
                  laod_line_chart();
                });

                $(document).on('click', '[data-action="load_chart"]', function () {
                  $('[name=exchange_id]').val($(this).data("exchange_id"));
                  $('[name=currency]').val($(this).data("currency"));
                  $('[name=base_currency]').val($(this).data("base_currency"));
                  laod_line_chart();
                });

                $(window).resize(function() {
                  resize_pair_list();
                });

                function resize_pair_list() {
                  $('#load_chart_pair').height($(window).height() - $('header').height() - $('#chart').height() - $('#load_chart').height() - 3);
                }
        	});

        };

        return TradeChart;
    })();
}).call(this);
