(function () {
    this.File = (function () {

        function File() {
            this.setListener();
        }

        File.prototype.setListener = function () {

            $('[data-action="delete_file"]').click(function () {
            	// ファイル名クリア
            	$(this).closest('.file_wrapper').find('.filename').remove();
                var filename_name = $(this).closest('.file_wrapper').find('video').length ? 'video_filename' : 'image_filename';
                $(this).closest('.file_wrapper').append('<input type="hidden" class="filename"name="'+filename_name+'['+$(this).closest('.file_wrapper').find('[type=file]').data('num')+']">');
                // No Image画像表示
                $(this).closest('.file_wrapper').find('img:not(.profile)').attr('src', '/images/noImage.png');
                $(this).closest('.file_wrapper').find('img.profile').attr('src', '/images/noImage_profile.png');
                $(this).closest('.file_wrapper').find('video').attr('src', '');
            });

            $('[data-action="select_file"]').click(function () {
                $(this).closest('.file_wrapper').find('input[type=file]').click();
            });

            $('input[type=file]').change(function () {
                var file = $(this).prop('files')[0];

                // ファイル形式チェック
                var acceptedType = $(this).attr('accept') == 'image/*' ? /^image\/(gif|jpe?g|png)$/i : /^video\/(mov|mp4|quicktime)$/i;
                if (!file.type || !acceptedType.test(file.type)) {
                    $(this).val(''); // inputタグのファイルデータクリア
                    var msg_part = $(this).attr('accept') == 'image/*' ? 'jpeg, gif, png' : 'mov, mp4';
                    show_error('file_type', '対応していないファイル形式です。'+msg_part+'のいずれをファイルを選んでください。');
                    return;
                }
                // ファイルサイズチェック
                var max_size_mb = 15; // 15M上限
                if (!file.size || file.size > max_size_mb * 1024 * 1024) {
                    $(this).val(''); // inputタグのファイルデータクリア
                    show_error('file_size', 'ファイルサイズが大きすぎます。'+max_size_mb+'MB以下のファイルを指定してください。');
                    return;
                }

                upload_file($(this));
            });

            function upload_file(input) {
            	var prev_src = input.closest('.file_wrapper').find('img, video').attr('src');
            	// ブラウザ上のデータからファイル表示
                show_file(input);

            	// アップロードデータ定義
            	var data = new FormData();
            	data.append('filedata', input.prop("files")[0]);
            	data.append('num', input.data('num'));

            	// 入力タグ更新
            	input.val('');

            	// waitフラグON
            	input.closest('.file_wrapper').find('.filename').remove();
            	var filename_name = input.closest('.file_wrapper').find('video').length ? 'video_filename' : 'image_filename';
            	input.closest('.file_wrapper').append('<input type="hidden" class="filename wait_flag" name="'+filename_name+'['+input.data('num')+']">');

            	// 非同期にアップロード
            	$.ajax(input.data('action'), {
                    type: 'POST',
                    data: data,
                    dataType: 'json',
                    cache: false,
                    timeout: 600000, // 600秒
                    processData: false,
                    contentType: false,
                }).done(function (data, textStatus, xhr) {
                  input.closest('.file_wrapper').find('.filename').val(data.filename);
                }).fail(function (xhr, textStatus, errorThrown) {
                	// エラー表示をして元のファイルを表示
                	var section = (input.attr('accept') == 'image/*' ? '画像' : '動画') +  input.data('num');
                	show_error(textStatus, section + 'のアップロードに失敗しました。通信環境を確認してください。');
                	input.closest('.file_wrapper').find('img, video').attr('src', prev_src);
                }).always(function () {
                	// waitフラグOFF
            		input.closest('.file_wrapper').find('.filename').removeClass('wait_flag');
                });
            }

            function show_file(input) {
                var file = input.prop('files')[0],
                    fileRdr = new FileReader(),
                    src;

                fileRdr.onload = function() {
                    src = fileRdr.result;
                    input.closest('.file_wrapper').find('img, video').attr('src', src);
                }
                fileRdr.readAsDataURL(file);
            }

            $(document).on('click', '[data-action=wait_and_submit]', function () {
            	setTimeout(function() {
        			$.blockUI();
        		}, 1);
            	wait_and_submit($(this).closest('form'));
            });
            function wait_and_submit(form) {
            	if (form.find('.wait_flag').length) {
            		// 0.5秒ごとに再帰
            		setTimeout(function() {
            			wait_and_submit(form);
            		}, 500);
            	} else {
            		// submit実行
            		form.find('[data-action=ajax_submit]').click();
            	}
            }
        };

        return File;
    })();
}).call(this);
