/**
 * 共通のJS
 */
// Vex設定
vex.defaultOptions.className = 'vex-theme-os';
vex.dialog.buttons.NO.text = 'キャンセル';

// blockUI設定
$.blockUI.defaults.message = '<div class="spinner"></div>';
$.blockUI.defaults.css.background = 'none';
$.blockUI.defaults.css.border     = 'none';
$.blockUI.defaults.overlayCSS.backgroundColor = 'white';
$.blockUI.defaults.baseZ = '10000';

/**
 * 共通のajax_submit処理
 */
$(document).on('click', '[data-action="ajax_submit"]', function () {
	var form = $(this).closest('form');

	$.ajax(form.attr('action'), {
        type: form.attr('method'),
        data: form.serialize(),
        dataType: 'json',
        cache: false,
        timeout: 60000, // 60秒
        beforeSend: function () {
        	if (!form.find('input[name=spinner_off]').length) {
        		$.blockUI();
        	}
        }
    }).done(function (data, textStatus, xhr) {
				if (data.href) {
					location.href = data.href;
				} else if (data.success_msg) {
					show_success(data.success_msg);
				}
    }).fail(function (xhr, textStatus, errorThrown) {
				if (xhr.status == 400) {
						show_validation_error(form, xhr.responseJSON.message);
				} else if (xhr.status == 500) {
						show_validation_error(form, 'システムエラー：しばらくお待ちください。');
				} else {
						show_error(textStatus, 'ネットワークエラー：通信環境を確認してください。');
				}
    }).always(function () {
        $.unblockUI();
        // パスワード入力欄クリア
        form.find('input[type=password]').val('');
    });
});

/**
 * 入力エラー表示
 */
function show_validation_error(form, msg) {
    form.find('.validation_error_msg').remove();

    if (Object.prototype.toString.call(msg) === '[object Object]') {
        Object.keys(msg).forEach(function (key) {
        	var input = form.find('.checkbox_list[name='+replace_sc(key)+'], :input:not([type=checkbox])[name='+replace_sc(key)+']');
        	input.after('<div class="validation_error_msg">'+msg[key]+'</div>');
        });
        $("html, body").animate({scrollTop:form.find('.validation_error_msg').offset().top - 145}, 800);
    } else {
        show_error('validation_error', msg);
    }
		function replace_sc(str) {
			return str.replace(/\[/g, "\\[").replace(/\]/g, "\\]");
		}
}

/**
 * エラー表示（入力エラー以外）
 */
function show_error(status, msg) {
    // アラート表示
    vex.dialog.alert({
        message: msg,
        callback: function () {
            // セッションタイムアウトの場合、ログイン画面表示
            if (status == 'session_timeout') {
                location.href = '/user/login_input';
            }
        }
    });
}

/**
 * 成功メッセージ表示（リダクレクト時）
 */
$(function(){
	var msg = $('#toast_message').data('text');
    if (msg.length) {
    	show_success(msg, $('#toast_message').data('time'));
        // メッセージクリア（ブラウバック対策）
        $('#toast_message').data('text', '');
    }
});
function show_success(msg, timeOut) {
    toastr.options = {
    		"positionClass": "toast-bottom-full-width",
    		"timeOut": timeOut ? timeOut : "3300",
    }
    toastr.success(msg);
}

/**
 * リモーダル表示
 */
$(document).on('click', '[data-action^="remodal_request"]', function () {
    var action = $(this).attr('data-action');
    var template = Handlebars.compile($('#'+action+'_template').html());
    $('[data-remodal-id='+action+']').html(template({}));
    $('[data-remodal-id='+action+']').remodal({hashTracking: false}).open();
});

function ajax_load(form, success, fail) {
    $.ajax(form.attr('action'), {
        type: form.attr('method'),
        data: form.serialize(),
        dataType: 'json',
        cache: false,
        timeout: 20000, // 20秒
        beforeSend: function () {
        	form.find('.no_data_msg').remove();
					if (form.find('input[name=loading_img_on]').length > 0) {
						form.append(
	                '<div class="loading">' +
	                '    <img src="/images/loading.gif">' +
	                '    <span>読み込んでいます..</span>' +
	                '</div>'
	          );
						$('.loading').fadeIn(1000);
	        } else if (form.find('input[name=spinner_on]').length > 0) {
	        	$.blockUI();
	        }
        }
    }).done(function (data, textStatus, xhr) {
      // コールバック関数実行
    	success(data);
    }).fail(function (xhr, textStatus, errorThrown) {
    	// 失敗用コールバック関数実行
    	fail();
    }).always(function () {
        $('.loading').remove();
				$.unblockUI();
    });
}

$(document).on('click', 'a[data-action=show_desc]', function () {
	$(this).closest('dt').find('div.desc').slideToggle();
});

function is_pc() {
	var ua = navigator.userAgent.toLowerCase();
	if (ua.indexOf('iphone')  > 0 ||
		ua.indexOf('ipad')    > 0 ||
		ua.indexOf('android') > 0   ) {
		return false;
	}
	return true;
}

function local_datetime(utc_date) {
		if (!utc_date) return;
    var date = new Date(utc_date);
    return date.getFullYear()+"/"+
		       ("0"+(date.getMonth()+1)).slice(-2)+"/"+
					 ("0"+date.getDate()).slice(-2)+" "+
					 ("0"+date.getHours()).slice(-2)+":"+
					 ("0"+date.getMinutes()).slice(-2);
}

function round(number, precision, locale) {
	if (precision == undefined) {
		rouned = Math.round(number);
	} else {
		var factor = Math.pow(10, precision);
		rouned =  Math.round(number * factor) / factor;
	}
	rouned = String(rouned).indexOf('e') >= 0 ? rouned.toFixed(precision) : rouned;
	return locale === true  ? rouned.toLocaleString("ja-JP", {maximumFractionDigits: precision}) : rouned;
}

function currency_color(currency) {
	switch (currency) {
		case "jpy":  return "#2db1ff";
		case "btc":  return "#ffa425";
		case "bch":  return "#68da5a";
		case "btg":  return "#f7d145";
		case "eth":  return "#8d7eff";
		case "etc":  return "#55d453";
		case "lsk":  return "#714fff";
		case "fct":  return "#ffc136";
		case "xmr":  return "#ff743f";
		case "rep":  return "#ec55ff";
		case "zec":  return "#f5c462";
		case "ltc":  return "#c5c5c5";
		case "dash": return "#2399ff";
		case "xrp":  return "#24a4ff";
		case "xem":  return "#2ed8c0";
		case "doge": return "#fdd444";
		case "mona": return "#ece545";
		default:     return "#f4f4f4";
	}
}

function exchange_color(exchange_key) {
	switch (exchange_key) {
		case "coincheck": return "#20C2D3";
		case "bitbank":   return "#029688";
		case "zaif":      return "#6993b5";
		case "poloniex":  return "#0a6970";
		case "hitbtc":    return "#289ac2";
		case "bitflyer":  return "#468ccb";
		default:          return "#f4f4f4";
	}
}

$(document).ready(function(){
			var form = $('#load_comment_left:visible');
			// オフセット初期化（Chromeのブラウザバック対策）
			form.find('input[name=offset]').val(0);
			laod_comment();

			function laod_comment() {
				if (!form.length) return;

				var success = function(res) {
						Object.keys(res.comments).forEach(function (key) {
								var row = res.comments[key];
								form.find('ul').append(
										'<a href="/article/detail/'+row['article_id']+'"><li>' +
										'    <div class="user">' +
										'        <div class="user_img" style="background-image:url('+row['user_image_path']+')"></div>' +
										'        <div class="user_name">'+row['user_name']+
										'            <span class="intro">'+row['user_short_intro']+'</span>' +
										'        </div>' +
										'        <div class="commented_at">'+local_datetime(row['updated_at'])+'</div>' +
										'    </div>' +
										'    <div class="comment">'+row['comment'].replace(/\r?\n/g, '<br>')+'</div>' +
										'    <div class="article_title">'+row['title']+'</div>' +
										'    <div class="sub_comment">にコメントしました。</div>' +
										'</li></a>'
								);
								form.find('ul li').fadeIn(1000);
						});

						if (!form.find('ul li').length) {
							form.find('ul').append('<div class="no_data_msg">コメントはありません。</div>');
							form.find('.no_data_msg').show();
						}

						if (Object.keys(res.comments).length) {
								form.find('[name=offset]').val(
										parseInt(form.find('[name=offset]').val()) + Object.keys(res.comments).length
								);
						}
				};

				var fail = function() {}

				ajax_load(form, success, fail);
			}
});
