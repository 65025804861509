(function () {
    this.AutoTrade = (function () {
        function AutoTrade() {
            this.setListener();
        }

        AutoTrade.prototype.setListener = function () {

        	$(document).ready(function(){
                // オフセット初期化（Chromeのブラウザバック対策）
                var form = $('#load_auto_trade');
                form.find('input[name=offset]').val(0);
                laod();

                function laod() {
                    if (!form.length) { return }

                    var success = function(data) {
                        Object.keys(data.arbitrages).forEach(function (key) {
                            var row = data.arbitrages[key];
                            form.find('ul').append(
                              '<a href="'+(form.find('ul.no_link').length ? 'javascript:void(0)' : '/auto_trade/input/'+row.arbitrage_id)+'"><li>' +
                              '    <div class="left_top">' +
                              '        <div class="pair"><img class="currency_img" src="/images/currency/'+row.currency+'.png">'+row.currency.toUpperCase()+'/'+row.base_currency.toUpperCase()+'</div>' +
                              '    </div>' +
                              '    <div class="status '+row.status+'">'+(row.status == 'running' ? '稼働中' : '停止中').toUpperCase()+'</div> '+
                              '    <div class="right"><span class="title">数量/回：</span>'+round(row.trade_amount, 8, true)+' '+row.currency.toUpperCase()+'</div>' +
                              '    <div class="right"><span class="title">発注条件閾値：</span>'+round(row.exec_criterion*100, 8, true)+' %</div>' +
                              '    <div class="middle_line_title">今月の損益（発注時基準概算）</div>'+
                              '    <div class="middle_line"></div>'+
                              (row.currency_profit != 0 ?
                              '    <div class="right profit_summary"><span class="'+(row.currency_profit > 0 ? 'plus' : '')+' '+(row.currency_profit < 0 ? 'minus' : '')+'">'+
                                           (row.currency_profit > 0 ? '+' : '')+round(row.currency_profit, 4, true)+' '+row.currency.toUpperCase()+'</span>'+
                              '    </div>' : '')+
                              '    <div class="right profit_summary"><span class="'+(row.base_currency_profit > 0 ? 'plus' : '')+' '+(row.base_currency_profit < 0 ? 'minus' : '')+'">'+
                                           (row.base_currency_profit > 0 ? '+' : '')+round(row.base_currency_profit, 4).toLocaleString("ja-JP", {maximumFractionDigits: (row.base_currency == 'jpy' ? 0 : 4)})+' '+row.base_currency.toUpperCase()+'</span>' +
                              '    </div>' +
                              '</li><a/>'
                            );
                            form.find('ul li').fadeIn(1000);
                        });

                        if (!form.find('ul li').length) {
                        	form.append('<div class="no_data_msg">現在自動売買の設定は登録されていません。</div>');
                        	form.find('.no_data_msg').fadeIn(1000);
                        }
                    };

                    var fail = function() {}

                    ajax_load(form, success, fail);
                }
        	});

          $(document).ready(function(){
            var load_lock = false;
            var form = $('#load_auto_trade_history');
            form.find('input[name=arbitrage_trade_id]').val('');
            laod();

            $(window).scroll(function () {
                if ($(document).height() > ($(window).height() + $(window).scrollTop() + 50) || load_lock) {
                    return;
                }
                laod();
            });

            function laod() {
                if (!form.length) return;
                load_lock = true;
                var success = function(data) {
                    Object.keys(data.arbitrage_trade).forEach(function (key) {
                        var row = data.arbitrage_trade[key];
                        form.find('ul').append(
                          '<li>' +
                          '    <div>' +
                          '        <div class="left"><span class="'+row.action+'">'+row.action.toUpperCase()+'</span> <span class="exchange">'+row.exchange_name+'</span></div>' +
                          '        <div class="right"><span class="title">市場レート：</span>'+round(row.market_rate, 8, true)+'</div>' +
                          '    </div>' +
                          '    <div>' +
                          '        <div class="left">'+local_datetime(row.created_at)+'</div>' +
                          '        <div class="right"><span class="title">数量：</span>'+round(row.amount, 8, true)+'</div>' +
                          '    </div>' +
                          '</li>'
                        );
                        form.find('ul li').fadeIn(1000);
                    });

                    if (!form.find('ul li').length) {
                      form.append('<div class="no_data_msg">この設定の取引履歴はありません。</div>');
                      form.find('.no_data_msg').fadeIn(1000);
                    }

                    var length = Object.keys(data.arbitrage_trade).length;
                    if (length) {
                        form.find('[name=arbitrage_trade_id]').val(data.arbitrage_trade[length-1].arbitrage_trade_id);
                        load_lock = false;
                    }
                };
                var fail = function() {
                    load_lock = false;
                }
                ajax_load(form, success, fail);
            }
          });

          $(document).on('change', '[name=pair_id]', function () {
            var main_form = $('#input_main');
            var exchange_amount_form = $('#exchange_amount_form');
            exchange_amount_form.find('[name=pair_id]').val(main_form.find('[name=pair_id]').val());

            main_form.find('select[name=trade_amount] option').remove();
            main_form.find('.checkbox_list[name=user_exchange_ids\\[\\]] .checkbox').remove();

            var success = function(data) {
                Object.keys(data.trade_amount).forEach(function (key) {
                    main_form.find('select[name=trade_amount]').append('<option value="'+key+'">'+data.trade_amount[key]+'</option>');
                });
                Object.keys(data.arbitrage_exchanges).forEach(function (key) {
                    main_form.find('.checkbox_list[name=user_exchange_ids\\[\\]]')
                      .append('<label class="checkbox">'+
										          '    <input type="checkbox" name="user_exchange_ids[]" value="'+key+'"/>'+
										          '    <span class="checkbox-icon"></span> <span class="checkbox-text">'+data.arbitrage_exchanges[key]+'</span><br>'+
									            '</label>');
                });
                var error_msg = Object.keys(data.arbitrage_exchanges).length < 2 ? 'このペアで取引可能な取引所は2つ以上連携されてません。' : '';
                show_validation_error(main_form, {'pair_id': error_msg})
            };

            var fail = function() {}

            ajax_load(exchange_amount_form, success, fail);
          });

          $(document).on('click', '[data-action=confirm_exec]', function () {
            var this_button = $(this);
            var msg = this_button.closest('form').find('[name=prev_status]').val() != 'running' && this_button.closest('form').find('[name=status]:checked').length ? '自動売買スイッチを「稼働」にすると、すぐに自動売買が開始されます。<br><br>' : '';
            vex.dialog.confirm({
                message: msg + this_button.text()+'します。よろしいですか？',
                callback: function (value) {
                    if (value === true) {
                      this_button.closest('form').find('[data-action=ajax_submit]').click();
                    }
                }
            });
          });

          $(document).on('click', '[data-action=confirm_delete]', function () {
            var this_button = $(this);
            vex.dialog.confirm({
                message: '削除すると、この自動売買設定の取引履歴や損益は確認できなくなります。<br><br>削除します。よろしいですか？',
                callback: function (value) {
                    if (value === true) {
                      this_button.closest('form').find('[data-action=ajax_submit]').click();
                    }
                }
            });
          });
        };

        return AutoTrade;
    })();
}).call(this);
