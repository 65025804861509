(function () {
    this.Balance = (function () {
        function Balance() {
            this.setListener();
        }

        Balance.prototype.setListener = function () {

        	$(document).ready(function(){
                var chart = null;
                var form = $('#load_balance');
                laod(true);
                // setTimeout(laod,   7000);
                // setTimeout(laod,  15000);
                // setTimeout(laod,  30000);

        		    function laod(is_first) {
                    if (!form.length) return;
                    var success = function(res) {
                        set_total(res);
                        set_list(res);
                        set_chart(res, is_first);
                        set_last_updated_at(res);
                    };
                    var fail = function() {}
                    ajax_load(form, success, fail);
                }

                function set_total(res) {
                  var unit = $('[name=base_currency]').val();
                  if (unit == "%") {
                    $("#balance_total").hide();
                  } else {
                    $("#balance_total").show();
                    $("#balance_total #amount").text(round(res.sum, 0, true)+" JPY");
                  }
                }

                function set_list(res) {
                  form.find('ul li').remove();
                  Object.keys(res.balance).forEach(function (i) {
                    var row = res.balance[i];
                    var unit = $('[name=base_currency]').val();
                    if (unit == "%") {
                      var amount = round(Number(row.rated_amount)/Number(res.sum)*100, 1, true);
                    } else if (unit) {
                      var amount = round(row.rated_amount, 0, true);
                    } else {
                      var amount = round(row.amount, 4, true);
                      unit = row.currency;
                    }
                    form.find('ul').append(
                        '<li>' +
                        '    <img class="currency_img" src="/images/currency/'+row.currency+'.png" onerror="this.src=\'/images/currency/default.png\';">' +
                        '    <div class="currency">'+row.currency.toUpperCase()+'</div>' +
                        '    <div class="amount">'+amount+' <span class="currency">'+unit.toUpperCase()+'</span></div>' +
                        '</li>'
                    );
                  });
                  update_no_data_msg(res);
                }

                function set_last_updated_at(res) {
                  var length = Object.keys(res.balance).length;
                  if (!length) return;
                  $('#last_updated_at #date').text(local_datetime(res.balance[length-1].created_at));
                  $('#last_updated_at').show();
                }

                function set_chart(res, animation) {
                  var unit = $('[name=base_currency]').val();
                  var ctx = document.getElementById('pie_chart').getContext('2d');
                  if (chart) chart.destroy();
                  chart = new Chart(ctx, {
                    type: 'pie',
                    data: {
                        labels: $.map(res.balance, function(v, i) { return v.currency.toUpperCase() }),
                        datasets: [{
                            backgroundColor: $.map(res.balance, function(v, i) { return currency_color(v.currency) }),
                            data: $.map(res.balance, function(v, i) {
                              return round(
                                unit == 'jpy' ? v.rated_amount : Number(v.rated_amount) / Number(res.sum) * 100
                              )
                            }),
                            borderWidth: Object.keys(res.balance).length == 1 ? 0 : 1.3,
                            hoverBorderColor: "white",
                        }]
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        animation: {
                          animateRotate: animation,
                          duration: 700,
                        },
                        legend: { display: false },
                        // cutoutPercentage: 55,
                        tooltips: {
                          callbacks: {
                            label: function (tooltipItem, data) {
                              var row = res.balance[tooltipItem.index];
                              var label = data.labels[tooltipItem.index];
                              if (unit == 'jpy') {
                                var amount = round(row.rated_amount, 0, true);
                              } else if (unit == '%') {
                                var amount = round(Number(row.rated_amount) / Number(res.sum) * 100, 0, true);
                              } else {
                                var amount = round(row.amount, 4, true);
                              }
                              return label + '：' + amount + ' '+ unit.toUpperCase();
                            }
                          }
                        }
                    }
                  });
                }

                function update_no_data_msg(res) {
                  $("#no_data_msg").remove();
                  if (!Object.keys(res.balance).length) {
                    form.append('<div id="no_data_msg">対象の通貨はありません。</div>');
                    $('#no_data_msg').fadeIn(1000);
                  }
                }

                function calc_rate(amount, target_currency, base_currency, rate) {
                  var float_amount = parseFloat(amount);
                  if (target_currency == base_currency || ["jpy", "btc"].indexOf(base_currency) < 0) {
                    return round(float_amount, (target_currency == "jpy" ? 0 : 8));
                  }
                  if (target_currency == "jpy") {
                    var calculated = round(amount / rate["jpy"][base_currency], 8);
                  } else {
                    var btc_base_amount = (target_currency == "btc" ? float_amount : float_amount * rate["btc"][target_currency]);
                    var calculated =  (base_currency == "btc" ? round(btc_base_amount, 8) : round(btc_base_amount * rate[base_currency]["btc"]));
                  }
                  return calculated ? calculated : 0;
                }

                $(document).on('change', '[name=user_exchange_id], [name=type], [name=base_currency]', function () {
                  if ($('[name=type]').val() == 'after_trade') {
                    $('[name=base_currency]').val('');
                    $('[name=base_currency] option:not([value=""])').hide();
                  } else {
                    $('[name=base_currency] option').show();
                  }
                  laod(true);
                });

                $('[data-action="exchange_modal"]').click((function () {
                    var template = Handlebars.compile($('#exchange_modal_template').html());
                    $('[data-remodal-id="exchange_modal"]').html(template({}));
                    $('[data-remodal-id="exchange_modal"]').remodal({hashTracking: false}).open();
                }));
        	});

        };

        return Balance;
    })();
}).call(this);
