(function () {
    this.Account = (function () {
        function Account() {
            this.setListener();
        }

        Account.prototype.setListener = function () {

            $(document).on('change', '[name=type]', function () {
            	ctrl_display_recieve_invitation($(this));
            });
            ctrl_display_recieve_invitation($('[name=type]:checked'));
            function ctrl_display_recieve_invitation(trigger) {
            	if (trigger.val() == 1) {
            		$('[name=recieve_invitation]').closest('dd').show();
            	} else {
            		$('[name=recieve_invitation]').closest('dd').hide();
            	}
            }

            $(document).on('click', '[data-action=delete_user]', function () {
                vex.dialog.confirm({
                    message: '退会されたアカウントのデータを元に戻すことはできなくなります。<br>退会します。よろしいですか？',
                    callback: function (value) {
                        if (value === true) {
                        	$('[data-action=delete_user]').closest('form').find('[data-action=ajax_submit]').click();
                        }
                    }
                });
            });

        }
        return Account;
    })();
}).call(this);
