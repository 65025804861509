(function () {
    this.Top = (function () {
        function Top() {
            this.setListener();
        }

        Top.prototype.setListener = function () {
        }
        return Top;
    })();
}).call(this);
