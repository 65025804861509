(function () {
    this.Notice = (function () {
        function Notice() {
            this.setListener();
        }

        Notice.prototype.setListener = function () {

        	$(document).ready(function(){
                var load_lock = false;
                // オフセット初期化（Chromeのブラウザバック対策）
                var form = $('#load_notice');
                form.find('input[name=offset]').val(0);
                laod();

                $(window).scroll(function () {
                    if ($(document).height() > ($(window).height() + $(window).scrollTop() + 50) || load_lock) {
                        return;
                    }
                    laod();
                });

                function laod() {
                    load_lock = true;
                    if (!form.length) { return }

                    var success = function(data) {
                        Object.keys(data.notices).forEach(function (key) {
                            var row = data.notices[key];
                            form.find('ul').append(
                              '<a href="'+row['url']+'"><li>' +
                              '    <div class="notice">' +
                              '        <div class="image" style="background-image:url('+row['image_url']+')"></div>' +
                              '        <div class="text">' +
                              '            <div class="title">'+row['title']+'</div>' +
                              '            <div class="created_at">'+local_datetime(row['created_at'])+'</div>' +
                              '        </div>' +
                              '    </div>' +
                              '</li><a/>'
                            );
                        });

                        if (!form.find('ul li').length) {
                        	form.append('<div class="no_data_msg">現在お知らせはありません。</div>');
                        }

                        if (Object.keys(data.notices).length) {
                            form.find('[name=offset]').val(
                                parseInt(form.find('[name=offset]').val()) + Object.keys(data.notices).length
                            );
                            load_lock = false;
                        }
                    };

                    var fail = function() {
                        load_lock = false;
                    }

                    ajax_load(form, success, fail);
                }
        	});

        };

        return Notice;
    })();
}).call(this);
