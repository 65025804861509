(function () {
    this.Article = (function () {
        function Article() {
            this.setListener();
        }

        Article.prototype.setListener = function () {

        	$(document).ready(function(){
                var load_lock = false;

                $(window).scroll(function () {
                    if ($(document).height() > ($(window).height() + $(window).scrollTop() + 50) || load_lock) {
                        return;
                    }
                    laod();
                    laod_comment();
                });

                function laod() {
                    var form = $('#load_article');
                    load_lock = true;
                    if (!form.length) return;

                    var success = function(res) {
                        Object.keys(res.articles).forEach(function (key) {
                            var row = res.articles[key];
                            form.find('ul').append(
                                '<a href="/article/detail/'+row['article_id']+'"><li>' +
                                '    <div class="article">' +
                                '        <h2 class="title">'+row['title']+'</h2>' +
                                         (row['image_url'] ? '<div class="article_img" style="background-image:url('+row['image_url']+')"></div>' : '') +
                                '        <div class="medium"><span>'+row['medium_name']+'</span>　<span>'+local_datetime(row['created_at'])+'</span></div>' +
                                '    </div>' +
                                (row['comment_user_id'] ? '    <div class="article_comment">' +
                                '        <div class="user_img" style="background-image:url('+(row['comment_user_id'] ? row['comment_user_image_path'] : row['picked_user_image_path'])+')"></div>' +
                                '        <div class="user_name">'+
                                         (row['comment_user_id'] ? row['comment_user_name'] : row['picked_user_name'])+
                                '        <span class="intro">'+(row['comment_user_id'] ? row['comment_user_short_intro'] : row['picked_user_short_intro'])+'</span>' +
                                '        </div>' +
                                '        <div class="comment">'+row['comment']+'</div>' +
                                '    </div>' : '')+
                                '</li></a>'
                            );
                        });

                        if (!form.find('ul li').length) {
                        	form.append('<div class="no_data_msg">現在ニュースの投稿はありません。</div>');
                        	form.find('.no_data_msg').fadeIn(1000);
                        }

                        if (Object.keys(res.articles).length) {
                            form.find('[name=offset]').val(
                                parseInt(form.find('[name=offset]').val()) + Object.keys(res.articles).length
                            );
                            load_lock = false;
                        }
                    };

                    var fail = function() {
                        load_lock = false;
                    }

                    ajax_load(form, success, fail);
                }

                function laod_comment() {
                  var form = $('#load_comment');
                  load_lock = true;
                  if (!form.length) return;
                  if ($('ul#comment_data').next('div.no_data_msg').length) return;

                  var success = function(res) {
                      Object.keys(res.comments).forEach(function (key) {
                          var row = res.comments[key];
                          $('ul#comment_data').append(
                              '<li>' +
                              '    <div class="user">' +
                              '        <div class="user_img" style="background-image:url('+row['user_image_path']+')"></div>' +
                              '        <div class="user_name">'+row['user_name']+
                              '            <span class="intro">'+row['user_short_intro']+'</span>' +
                              '        </div>' +
                              '        <div class="commented_at">'+local_datetime(row['updated_at'])+'</div>' +
                              '    </div>' +
                              '    <h3 class="comment">'+row['comment'].replace(/\r?\n/g, '<br>')+'</h3>' +
                              '    <div class="action">' +
                              '        <form action="/article/create_like_comment" method="post">' +
                              '            <input type="hidden" name="spinner_off" value="1">' +
                              '            <input type="hidden" name="article_comment_id"  value="'+row['article_comment_id']+'">' +
                              '            <input type="hidden" name="login_user_liked" value="'+row['login_user_liked']+'">' +
                              '            <a class="btn btn_link like" href="javascript:void(0)" data-action="'+ (res.is_login != "" ? 'ajax_submit' : 'require_login') +'"><i class="ion-thumbsup"></i> いいね <span class="like_count">'+row['like_count']+'</span></a>' +
                              '        </form>' +
                              '    </div>' +
                              '</li>'
                          );
                      });

                      if (Object.keys(res.comments).length) {
                          form.find('[name=offset]').val(
                              parseInt(form.find('[name=offset]').val()) + Object.keys(res.comments).length
                          );
                          load_lock = false;
                      }
                  };

                  var fail = function() {
                      load_lock = false;
                  }

                  ajax_load(form, success, fail);
                }
        	});

          $(document).on('click', '[data-action=start_url]', function () {
            show_article_input();
          });

          function show_article_input(error_msg) {
            vex.dialog.open({
              message: (error_msg ? '<div class="error_msg">'+error_msg+'<div>' : '記事のURLを入力してください。'),
              input: ['<input type="text" name="url" placeholder="URL" />',].join(''),
              buttons: [
                  $.extend({}, vex.dialog.buttons.YES, { text: 'アップする' }),
                  $.extend({}, vex.dialog.buttons.NO, { text: 'キャンセル' })
              ],
              callback: function (value) {
                  if (value !== false) {
                    var form = $('form.vex-dialog-form');
                    $.ajax('/article/create', {
                          type: 'post',
                          data: form.serialize(),
                          dataType: 'json',
                          cache: false,
                          timeout: 20000, // 60秒
                          beforeSend: function () {
                          	$.blockUI();
                          }
                      }).done(function (data, textStatus, xhr) {
                          if (data.status == 'validation_error') {
                              show_article_input(data.msg);
                          } else {
                          	location.href = data.href;
                          }
                      }).fail(function (xhr, textStatus, errorThrown) {
                          show_error(textStatus, 'ネットワークエラー：通信環境を確認してください。');
                      }).always(function () {
                          $.unblockUI();
                      });
                  }
              }
            });
          }

          $(document).on('click', '[data-action=start_comment]', function () {
            $('#comment_ctrl').slideToggle();
          });

          $(document).on('click', '[data-action=cancel_comment]', function () {
            　$('#comment_ctrl').slideToggle();
          });

          $(document).on('click', '[data-action=update_comment]', function () {
            $('form#exec_comment').find('[data-action=ajax_submit]').click();
          });

          $(document).on('click', '[data-action=delete_comment]', function () {
            　var form = $(this).closest('form');
              vex.dialog.confirm({
                  message: 'コメントを削除します。よろしいですか？',
                  callback: function (value) {
                      if (value === true) {
                        form.find('[data-action=ajax_submit]').click();
                      }
                  }
              });
          });

          $(document).on('click', 'a.like[data-action=ajax_submit]', function () {
            // いいね押す前のいいねカウント
            var like_count = Number($(this).find('span.like_count').text());

            // いいね済みかどうかによって表示カウントを更新
              if ($(this).closest('form').find('input[name=login_user_liked]').val() >= 1) {
                like_count--;
                var login_user_liked = 0;
              } else {
                like_count++;
                var login_user_liked = 1;
              }
              $(this).find('span.like_count').text(like_count);
              $(this).closest('form').find('input[name=login_user_liked]').val(login_user_liked);
          });

          $(document).on('click', '[data-action=require_login]', function () {
            vex.dialog.confirm({
              message: 'ログインが必要です。ログイン画面に移動しますか？',
              callback: function (value) {
                  if (value === true) {
                    location.href = '/user/login_input';
                  }
              }
            });
          });
        };

        return Article;
    })();
}).call(this);
