(function () {
    this.Trade = (function () {
        function Trade() {
            this.setListener();
        }

        Trade.prototype.setListener = function () {

        	$(document).ready(function(){
                var load_lock = false;
                // オフセット初期化（Chromeのブラウザバック対策）
                var form = $('#load_trade');
                form.find('input[name=offset]').val(0);
                laod();

                $(window).scroll(function () {
                    if ($(document).height() > ($(window).height() + $(window).scrollTop() + 50) || load_lock) {
                        return;
                    }
                    laod();
                });

                function laod() {
                    load_lock = true;
                    if (!form.length) { return }
                    var success = function(data) {
                        set_list(data);
                        set_last_updated_at(data);
                    };
                    var fail = function() {
                        load_lock = false;
                    }
                    ajax_load(form, success, fail);
                }

                function set_list(data) {
                  Object.keys(data.trade).forEach(function (key) {
                      var row = data.trade[key];
                      var ordered_at = local_datetime(row.ordered_at);
                      form.find('ul').append(
                        '<li class="'+row.status+'">' +
                        '    <div>' +
                        '        <div class="left"><span class="pair">'+row.currency.toUpperCase()+'_'+row.base_currency.toUpperCase()+'</span> <span class="exchange">'+row.exchange_name+'</span></div>' +
                        '        <div class="right"><span class="title">レート：</span>'+round(row.rate, 8, true)+'</div>' +
                        '    </div>' +
                        '    <div>' +
                        '        <div class="left"><span class="'+row.action+'">'+row.action.toUpperCase()+'</span> '+($(window).width() < 350 ? ordered_at.slice(2) : ordered_at)+'</div>' +
                        '        <div class="right"><span class="title">数量：</span>'+round((row.status == "open" ? row.remaining_amount : row.amount), 8, true)+'</div>' +
                        '    </div>' +
                        '</li>'
                      );
                  });

                  if (!form.find('ul li').length) {
                    var switch_section = $('.switch_section a.selected').text();
                    form.append('<div class="no_data_msg">'+switch_section+'はありません。</div>');
                  }

                  if (Object.keys(data.trade).length) {
                      form.find('[name=offset]').val(
                          parseInt(form.find('[name=offset]').val()) + Object.keys(data.trade).length
                      );
                      load_lock = false;
                  }
                }

                function set_last_updated_at(res) {
                  if (!Object.keys(res.trade).length) return;
                  $('#last_updated_at #date').text(local_datetime(res.trade[0].created_at));
                  $('#last_updated_at').show();
                }
        	});

        };

        return Trade;
    })();
}).call(this);
