(function () {
    this.BalanceHistory = (function () {
        function BalanceHistory() {
            this.setListener();
        }

        BalanceHistory.prototype.setListener = function () {

        	$(document).ready(function(){
                var chart = null;
                laod();

                function laod() {
                    var form = $('#load_chart');
                    if (!form.length) return;
                    var success = function(res) {
                      set_chart(res);
                      set_current_balance(res);
                      set_last_updated_at(res);
                    };
                    var fail = function() {}
                    ajax_load(form, success, fail);
                }

                function set_chart(res) {
                  var data = res.chart.reverse();
                  var ctx = document.getElementById('line_chart').getContext('2d');
                  ctx.canvas.style.height = '230px';
                  if (chart) chart.destroy();
                  chart = new Chart(ctx, {
                    type: 'line',
                    data: {
                        labels: $.map(data, function(v, i) {
                          return local_datetime(v.date).substr(5, 5);
                        }),
                        datasets: [{
                            label: set_chart_label(data),
                            data: $.map(data, function(v, i) { return v.amount }),
                            pointRadius: 0,
                            lineTension: 0,
                            borderColor: "rgba(45, 177, 255, 0.5)",
                            borderWidth: 1.5,
                            backgroundColor: "rgba(45, 177, 255, 0.5)",
                        }]
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                    }
                  });
                }

                function set_chart_label(data) {
                  var length = Object.keys(data).length;
                  var precision = $('[name=currency]').val() == 'btc' ? 4 : 0;
                  var unit = $('[name=currency]').val().toUpperCase();
                  if ($('[name=type]').val() == 'balance') {
                    return length ? '現在資産：'+round(data[length-1].amount, precision).toLocaleString()+' JPY' : "";
                  }
                  return length ? '損益合計：'+round( $.map(data, function(v, i) { return v.amount }).reduce((prev, current) => Number(prev) + Number(current)), precision).toLocaleString()+' '+ unit : "";
                }

                function set_current_balance(res) {
                  $('#load_latest_sum').find('ul li').remove();
                  var chart = res.chart.reverse();
                  Object.keys(chart).forEach(function (i) {
                    var row = chart[i];
                    var precision = row.currency == 'btc' ? 4 : 0;
                    $('#load_latest_sum').find('ul').append(
                        '<li>' +
                        '    <div class="date">'+row.date+'</div>' +
                        '    <div class="amount">'+round(row.amount, precision).toLocaleString()+' <span class="currency">'+row.currency.toUpperCase()+'</span></div>' +
                        '</li>'
                    );
                  });

                  update_no_data_msg(res);
                }

                function update_no_data_msg(res) {
                  $("#no_data_msg").remove();
                  if (!Object.keys(res.chart).length) {
                    $('#load_latest_sum').append('<div id="no_data_msg">推移情報はありません。</div>');
                    $('#no_data_msg').fadeIn(1000);
                  }
                }

                function set_last_updated_at(res) {
                  var chart = res.chart.reverse();
                  var length = Object.keys(chart).length;
                  if (!length) return;
                  $('#last_updated_at #date').text(local_datetime(chart[length-1].updated_at));
                  $('#last_updated_at').show();
                }

                $(document).on('change', '[name=type], [name=currency], [name=period]', function () {
                  laod();
                });
        	});

        };

        return BalanceHistory;
    })();
}).call(this);
