(function () {
    this.User_exchange = (function () {
        function User_exchange() {
            this.setListener();
        }

        User_exchange.prototype.setListener = function () {

          $(document).ready(function(){
              first_param = location.search.substring(1).split('&')[0].split('=');
              if (first_param[0] == 'prv_fetch_class' && first_param[1] == 'auto_trade') {
                  vex.dialog.alert({
                      message: '自動売買の裁定取引（アービトラージ）には2箇所以上の取引所と連携する必要があります。<br>取引所を選んで連携してください。',
                  });
              }
          });

        	$(document).ready(function(){
                laod();

                function laod() {
                    var form = $('#load_exchange');
                    if (!form.length) { return }

                    var success = function(data) {
                        Object.keys(data.user_exchanges).forEach(function (key) {
                            var row = data.user_exchanges[key];
                            form.find('ul.sync').append(
                              '<a href="/user_exchange/input/'+row['user_exchange_id']+'?exchange_id='+row['exchange_id']+'"><li>' +
                              '    <div class="exchange">' +
                              '        <img class="exchange_img" src="/images/exchange/'+row.exchange_key+'.png">' +
                              '        <div class="text">' +
                              '            <div class="title">'+row.exchange_name+'</div>' +
                              '            <div class="sub">APIキー：'+row.partial_access_key+'***</div>' +
                              '        </div>' +
                              '    </div>' +
                              '</li><a/>'
                            );
                            form.find('ul.sync li').fadeIn(1000);
                        });

                        if (!form.find('ul.sync li').length) {
                        	form.find('ul.sync').append('<div class="no_data_msg">連携済みの取引所はありません。</div>');
                        	form.find('.no_data_msg').fadeIn(1000);
                        }

                        var sync_ex_ids = $.map(data.user_exchanges, function(v, i) { return v.exchange_id});

                        Object.keys(data.exchanges).forEach(function (key) {
                            var row = data.exchanges[key];
                            if (!sync_ex_ids.includes(row['exchange_id'])) {
                              form.find('ul.unsync').append(
                                '<a href="/user_exchange/input?exchange_id='+row.exchange_id+'"><li>' +
                                '    <div class="exchange">' +
                                '        <img class="exchange_img" src="/images/exchange/'+row.exchange_key+'.png">' +
                                '        <div class="text">' +
                                '            <div class="title">'+row.exchange_name+'</div>' +
                                '            <div class="sub">'+row.short_intro+'</div>' +
                                '        </div>' +
                                '    </div>' +
                                '</li><a/>'
                              );
                              form.find('ul.unsync li').fadeIn(1000);
                            }
                        });

                        if (!form.find('ul.unsync li').length) {
                        	form.find('ul.unsync').append('<div class="no_data_msg">未連携の取引所はありません。</div>');
                        	form.find('.no_data_msg').fadeIn(1000);
                        }

                    };

                    var fail = function() {}

                    ajax_load(form, success, fail);
                }
        	});

          $(document).on('click', '[data-action=confirm_delete]', function () {
            var this_button = $(this);
            vex.dialog.confirm({
                message: '連携解除します。よろしいですか？',
                callback: function (value) {
                    if (value === true) {
                      this_button.closest('form').find('[data-action=ajax_submit]').click();
                    }
                }
            });
          });
        };

        return User_exchange;
    })();
}).call(this);
